import React, { useState, useContext } from "react"
import { Row, Col, Card, CardBody, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getCancelledOrders, searchUsers, getReasons, getLaundries, getLaundrylist } from "../../store/actions"
import {postWeb} from "helpers/api_helper"
import classNames from "classnames"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pages404 from "pages/Utility/pages-404";
import moment from 'moment-timezone';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import SocketContext from "common/socket";
import { useRef } from "react";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EditNote = props => {
  const [label, setLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");

  
  
  useEffect(() => {
    setSelectedItem(props.row.customer?.customer_care_note);
  }, [props]);

  useEffect(() => {
    setLabel(props.row.customer?.customer_care_note);
    setEmptyLabel("No Note")  
  }, [props]);

  const showEditable = () => {
    setShowLabel(false);
  }

  const handleClose = () => {
    setShowLabel(true);
  }
  
  const handleSubmit = async () => {
    
      if(!selectedItem || selectedItem.trim() == ""){
        toastr.error("Please enter bag No ", `Required`);
        return;
      }

      if(!props.row?.customer?._id){
        toastr.error("Invalid customer ", `Required`);
        return;
      }

      
      postWeb("customers/update", {
        _id : props.row.customer?._id,
        customer_care_note : selectedItem
      }).then(() => {
        props.onUpdate.apply(null, [props.row._id, selectedItem]);
      });
      
      toastr.success(`Customer Care Note Updated Successfully`, `Customer Care Note Updated`);
      
      setShowLabel(true);
      return;

  }

  const onChangeItem = (e) => {
    setSelectedItem(e.target.value);
  }

  return (
    <>
    {showLabel?
    <>
          {label ? 
            <a onClick={showEditable} className="editable editable-click">{label}</a> : 
            <a onClick={showEditable} className="editable editable-click editable-empty text-danger">{emptyLabel}</a>}
        </> :
        <>
          <textarea 
            onChange={(e) => setSelectedItem(e.target.value)}
            className="form-control"
          >{selectedItem}</textarea>
          <div className="editable-buttons">
            <button type="submit" 
              onClick={handleSubmit}
              className="btn btn-success editable-submit btn-sm waves-effect waves-light">
                <i className="mdi mdi-check">
                </i>
            </button>
            <button 
              type="button"
              onClick={handleClose} 
              className="btn btn-danger editable-cancel btn-sm waves-effect waves-light">
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </>}
    </>
  );
};

EditNote.propTypes = {
  row: PropTypes.any,
  onUpdate: PropTypes.any
}

const CancelledOrdersList = () => {
  const dispatch = useDispatch()
  const {  users, laundryList } = useSelector(state => ({
    users : state.Users.users,
    laundryList: state.ecommerce.laudrylist,
  }))

  const reloadList = useRef();

  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  
  const [allOrderFilter, setAllOrderFilter] = useState({
    cancelledBy:'customer',
    category: ["online"],
    newCustomer: false,
    laundry: undefined,
    type: undefined,
    startDate,
    endDate
  })
  
  const socket = useContext(SocketContext);
  const myAudio = new Audio(API_BASE_URL + "/sounds/invoice.mp3");
  function formatDate(date) {
    const d = new Date(date);
    if (isNaN(d)) return ""; // Ensure date validity
  
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
  
    return `${year}-${month}-${day}`;
  }
// Add handlers to update the start and end date values
const handleStartDateChange = (e) => {
  const newStartDate = e.target.value;
  const endDate = new Date(allOrderFilter.endDate);
  const startDate = new Date(newStartDate);

  // Check if the date difference is more than 3 months
  const dateDifference = (endDate - startDate) / (1000 * 60 * 60 * 24 * 30); // difference in months

  if (dateDifference > 3) {
    toastr.error("The date range cannot exceed 3 months.");
    return;
  }

  setStartDate(newStartDate);
  setAllOrderFilter(prevState => ({
    ...prevState,
    startDate: newStartDate
  }));
};

const handleEndDateChange = (e) => {
  setEndDate(e.target.value);
  setAllOrderFilter(prevState => ({
    ...prevState,
    endDate: e.target.value
  }));
};

const handleApply = async (exportCsv = false) => {
  let apiData = {
    status: ["cancelled"],
    page: page,
    sortBy: "cancelledDateTime",
    sortDir: "desc",
    startDate: allOrderFilter.startDate, 
    endDate: allOrderFilter.endDate, 
  };


  if (allOrderFilter.category.length > 0) {
    apiData = { ...apiData, category: allOrderFilter.category };
  }
  if (allOrderFilter.laundry) {
    apiData = { ...apiData, laundry: allOrderFilter.laundry };
  }
  if (allOrderFilter.type) {
    apiData = { ...apiData, type: allOrderFilter.type };
  }
  if (allOrderFilter.dryClean) {
    apiData = { ...apiData, dryClean: allOrderFilter.dryClean };
  }
  if (allOrderFilter.cancelledBy) {
    apiData = { ...apiData, cancelledBy: allOrderFilter.cancelledBy };
  }

  if(allOrderFilter.newCustomer){
    apiData = { ...apiData, newCustomer: allOrderFilter.newCustomer };
  }
  
  if(allOrderFilter?.paid){
    apiData = { ...apiData, paid: allOrderFilter.paid };
  }
  if(allOrderFilter?.unpaid){
    apiData = { ...apiData, unpaid: allOrderFilter.unpaid };
  }

  if(exportCsv){
    apiData = {...apiData,isExport:true }
  }

  // Send the updated payload
  const response = await postWeb("orders/listAndExportCancelledOrders", apiData); // Pass apiData instead of allOrderFilter
  if (response) {
    if(response?.filePath){
      window.open(`${API_BASE_URL}${response?.filePath}`, '_blank');
      toastr.success("Cancelled orders exported successfully.");
      setStartDate( new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]);
      setEndDate(new Date().toISOString().split('T')[0]);
    } else{
      setProductData(response?.data || []);
      setTotalRecords(response?.total_orders || 0);
    }
    setIsLoading(false);

  }
}

  useEffect(() => {
    setProductData([])
    setIsLoading(true);
    handleApply();
  }, [page])


  useEffect(() => {
    if(isLoading){
      return;
    }
    setProductData([])
    setIsLoading(true);
    setTimeout(() => {
      handleApply();
    }, 100)
    

  }, [allOrderFilter.newCustomer])


  useEffect(() => {
    dispatch(getReasons({}))
    if(!users) dispatch(searchUsers({webPermissions: true}))
     dispatch(getLaundrylist({ status: true }))
    myAudio.preload = "auto";
    myAudio.load();

    socket.on("__order_updated__", data => {
      if(data.status == "cancelled"){
        myAudio.play();
        toastr.warning(`Order has been cancelled.`, `Order ID: ${data.orderID}`);
        
        if(page == 0){
          reloadList.current.apply(null, [data]);
        }
        
      }

    });

    return  () => {
      socket.off('__order_updated__');
    }

  }, [])

  useEffect(() => {
    
    const _productData = productData;

    reloadList.current = (data) => {

      if(productData.length > 0){
        let orders = productData;
        const totalOrders = productData.length;
        
        if (orders?.length && orders?.length > 0) {
          if(orders.length < 20){
            orders = [data?.orderData, ...orders];
            setProductData(orders);
            setTotalRecords(totalOrders+1);
          }
          else{
            orders.pop();
            orders = [data?.orderData, ...orders];
            setProductData(orders);
          }
        }
        else{
          setProductData([data?.orderData])
          setTotalRecords(1);
        }
      }
    }
  }, [productData])

  useEffect(() => {
    if(users.length> 0){
      setUserData(users[0]);
    }
  }, [users])


  useEffect(() => {
    const fetchLogs = async () => {
      setIsLoading(true);
      try {
        const response = await postWeb("orders/listAndExportCancelledOrders", allOrderFilter); // Update with actual API endpoint
        setProductData(response?.data || []);
        setTotalRecords(response?.total_orders || 0);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
      setIsLoading(false);
    };
    fetchLogs();
  }, []);
  
  const columns = [
    {
      dataField: 'orderID',
      text: 'Order ID',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : row?.orderID || "N/A")
    },
    {
      dataField: 'name',
      text: 'Customer Name',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : row?.customer?.name || "N/A")
    },
    {
      dataField: 'customerID',
      text: 'Customer ID',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : row?.customer?.customerID || "N/A")
    },
    {
      dataField: 'category',
      text: 'Category',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : row?.category || "N/A")
    },
    {
      dataField: 'paid',
      text: 'Payment Status',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : row?.paid ? 'Paid' : 'Not Paid')
    },
    {
      dataField: 'cancelledDateTime',
      text: 'Cancelled Date',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : (row?.cancelledDateTime || row?.createdAt) ?  moment(row?.cancelledDateTime || row?.createdAt).format('DD MMM YYYY HH:MM') : "N/A")
    },
    {
      dataField: 'refundAmount',
      text: 'Refund Amount',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : row?.orderRefund?.amount || "0")
    },
    {
      dataField: 'cancelledBy',
      text: 'Cancelled By',
      sort: false,
      formatter: (cellContent, row) => (isLoading ? <Skeleton count={1} /> : allOrderFilter.cancelledBy == 'customer' ? "Customer" : (row?.updatedBy?.name || "Admin") || "N/A")
    },
    {
      dataField: 'notes',
      text: 'Notes',
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) return <Skeleton count={1} />;
    
        const notes =
          row?.notes ||
          row?.orderCancellation?.reason?.reasonEn ||
          row?.orderCancellation?.otherReason ||
          "N/A";
    
        return (
          <div 
            style={{
              maxWidth: '250px', 
              whiteSpace: 'pre-wrap', // Allows multiline wrapping
              wordWrap: 'break-word', // Ensures long words break properly
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 3, // Limits text to 3 lines before truncating
              WebkitBoxOrient: 'vertical'
            }} 
            title={notes} // Tooltip on hover
          >
            {notes}
          </div>
        );
      }
    }
    
    
  ];
  
  
  

const updateContacted = (customer_id, i, contacted) => {
  postWeb("customers/update", {
    _id : customer_id,
    contacted : contacted
  }).then(() => {
    handleApply();
  });
  
  toastr.success(`Customer Care Note Updated Successfully`, `Customer Care Note Updated`);
  
}
const totalPages = Math.ceil(totalRecords / 10);


  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalRecords, // replace later with size(customers),
    custom: true,
  }


  const handlePaidChange = (status) => {
    setAllOrderFilter((prevState) => {
      const newState = { ...prevState };
  
      if (!prevState[status]) {
        // If checkbox is checked, add the key
        newState[status] = true;
      } else {
        // If checkbox is unchecked, remove the key
        delete newState[status];
      }
  
      return newState;
    });
  };
  
  const handleCategory = category => {
    let categoryData = [...allOrderFilter.category]
    let indexOfCat = categoryData.indexOf(category)
    if (indexOfCat !== -1) {
      categoryData.splice(indexOfCat, 1) 
    } else {
      categoryData.push(category)
    }
    setAllOrderFilter({ ...allOrderFilter, category: categoryData })
  }
  if( userData?.permissions?.allPermissions==true || userData?.permissions?.orders?.read==true){
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Kleen | Cancelled Orders list</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Orders" breadcrumbItem="Cancelled orders" />
           
          <Row className="my-2">
          <div
                  className={"row mt-2"}
                >
                  <Row>
                    <div className="col-md-2">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                      <h5 className="card-title text-center">Paid</h5>
                        <div className="control-group">
                        <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="paymentStatus"
                          id="paidCheckbox"
                          checked={!!allOrderFilter?.paid} // Ensure `undefined` is treated as false
                          onChange={() => handlePaidChange('paid')}
                        />
                        <label className="form-check-label" htmlFor="paidCheckbox">
                          Paid
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="paymentStatus"
                          id="unpaidCheckbox"
                          checked={!!allOrderFilter?.unpaid}
                          onChange={() => handlePaidChange('unpaid')}
                        />
                        <label className="form-check-label" htmlFor="unpaidCheckbox">
                          Unpaid
                        </label>
                      </div>
                     </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Category</h4>
                        <div className="control-group">
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter?.category?.includes(
                                "offline"
                              )}
                              id="debugInfo"
                              onChange={() => handleCategory("offline")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="debugInfo"
                            >
                              Offline Orders
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={allOrderFilter?.category?.includes(
                                "online"
                              )}
                              id="progressBar"
                              onChange={() => handleCategory("online")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="progressBar"
                            >
                              Online Orders
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Filter</h4>
                        <div>
                          <div className="mb-1">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm"
                              onChange={e =>
                                setAllOrderFilter({
                                  ...allOrderFilter,
                                  laundry: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Laundry</option>
                              {laundryList.map(item => {
                                return (
                                  <option
                                    key={item._id}
                                    value={item._id}
                                    selected={
                                      allOrderFilter.laundry === item._id
                                    }
                                  >
                                    {item.shortName
                                      ? item.shortName
                                      : item.nameEn}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Service Type</h4>
                        <div>
                          <div className="mb-1">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm"
                              onChange={e =>
                                setAllOrderFilter({
                                  ...allOrderFilter,
                                  type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="clothes">Clothes</option>
                              <option value="carpet">Carpet</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Cancelled By</h4>
                        <div>
                          <div className="mb-1">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm"
                              onChange={e =>
                                setAllOrderFilter({
                                  ...allOrderFilter,
                                  cancelledBy: e.target.value,
                                })
                              }
                            >
                              <option defaultChecked value="customer">Customer</option>
                              <option value="admin">Admin</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div className="col-md-2 d-flex">
                    <div className="card card-body card-minimal-spacing remove-bottom-margin">
                    <h4 className="card-title text-center">Actions</h4>
                      <div className="d-flex flex-column justify-content-center gap-2">
                      <button
                        type="button"
                        onClick={() => handleApply()}
                        className="btn btn-primary w-xs waves-effect waves-light btn-sm"
                        style={{ height: "fit-content" }}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        onClick={() => handleApply(true)}
                        className="btn btn-success w-xs btn-sm "
                        style={{ height: "fit-content" }}
                      >
                        Export
                      </button>
                      </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                 
                    <div className="col-md-2 d-flex">
                      <div className="card card-body card-minimal-spacing remove-bottom-margin">
                        <h4 className="card-title text-center">Date range</h4>
                        <div>
                          <div className="mb-1">
                            <label>From:</label>
                            <Input
                             type="date"
                             value={startDate}
                             onChange={e => {
                              const selectedDate = e.target.value;
                              if (endDate && new Date(selectedDate) > new Date(endDate)) {
                                toastr.error('Start Date cannot be later than End Date.');
                              } else {
                                handleStartDateChange(e)
                              }}}
                            />
                          </div>
                          <div className="mb-1">
                          <label>To:</label>
                            <Input
                             disabled={isLoading}
                             type="date"
                             value={endDate}
                             onChange={e => {
                               const selectedDate = e.target.value;
                               if (startDate && new Date(selectedDate) < new Date(startDate)) {
                                  toastr.error('End Date cannot be earlier than Start Date.');
                               } else {
                                 handleEndDateChange(e)
                               }
                             }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
          </Row>
          <Row id="cancel-order-list" className="mt-10">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center mb-2">
                        <span className={`me-2 ${allOrderFilter.newCustomer? "text-success font-weight-semibold": ""}`}>Only New Customers</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={allOrderFilter.newCustomer}
                            onChange={(e) => { 
                              setPage(1)
                              setAllOrderFilter(prev=>({...prev, newCustomer:!allOrderFilter.newCustomer}))
                             }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  
                  {
                    isLoading ? (
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="table-responsive">
                            <div className="react-bootstrap-table">
                              <table className="table table align-middle table-nowrap">
                                <thead className="table-light">
                                  <tr>
                                    <th>Order</th>
                                    <th>Customer</th>
                                    <th>Mobile</th>
                                    <th>Start Date</th>
                                    <th>Cancelled Date</th>
                                    <th>Reason</th>
                                    <th>Total Orders</th>
                                    <th>First Order</th>
                                    <th>Last Order</th>
                                    <th>Registration</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {[...Array(10).keys()].map((k) => (
                                    <tr key={k}>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                  </tr>  
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={productData}
                      >
                        {
                          ({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={productData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>


                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          // selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          // headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />

                                      </div>
                                    </Col>
                                  </Row>

                                  
                                </React.Fragment> 
                              )
                              }
                            </ToolkitProvider>
                          )
                        }</PaginationProvider>
                    )
                  }



                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="align-items-md-center mt-30 row">
            <div className="inner-custom-pagination d-flex col">
              <div className="text-md-right ms-auto">
              <ul className="pagination react-bootstrap-table-page-btns-ul">
                  {page > 6 && (
                    <li className="page-item" title="First page">
                      <a onClick={() => setPage(1)} className="page-link">First</a>
                    </li>
                  )}
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((element) =>
            page >= element - 5 && page <= element + 5 ? (
              <li
                key={element}
                className={classNames("page-item", { active: element === page })}
                title={`Page ${element}`}
              >
                <a onClick={() => setPage(element)} className="page-link">
                  {element}
                </a>
              </li>
            ) : null
          )}
          {page < totalPages - 5 && (
            <li className="page-item" title="Last page">
              <a onClick={() => setPage(totalPages)} className="page-link">
                Last
              </a>
            </li>
          )}
            </ul>
              </div>
            </div>
          </div>
        </div>
      </div >
    </React.Fragment >
  )
  }
  else{
    return <Pages404 />
  }
}

export default CancelledOrdersList;