import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Button, CardText,Label, Modal } from "reactstrap";
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import { getOrderByID } from "store/actions"; // Assuming these actions are defined
import "toastr/build/toastr.min.css";
import { postWeb } from "helpers/api_helper";
import Select from "react-select"

const CancelOrder = () => {
  const dispatch = useDispatch();
  const { orderByID } = useSelector(state => ({
    orderByID: state.Orders.orderByID,
  }));

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

  const initailStateOfErrors = {
    orderID: "",
    reason: "",
    approvedBy: "",
    notFound: "",
    failed: "",
  }

  const paymentStatusOptions = [{ label:'Paid', value:'paid'},{label:'Not Paid', value:'notPaid'}]
  const cancelledByOptions = [{ label:'Admin', value:'admin'},{label:'Customer', value:'customer'}]
  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [reason, setReason] = useState("");
  const [error, setError] = useState(initailStateOfErrors);
  const [approvedBy, setApprovedBy] = useState("");
  const [isExport, setIsExport] = useState(false);
  const [isRefund, setRefund] = useState(false);
  const [cancelledBy, setCancelledBy] = useState(cancelledByOptions[0]);
  const [paymentStatus, setPaymentStatus] = useState(paymentStatusOptions[0]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [refundMethod, setRefundMethod ] = useState('');
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  );
  const [refundModalToggel, setRefundModalToggel] = useState(false);
  const [refundAmount, setRefundAmount] = useState('')

  function formatDate(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    const _orderByID = orderByID?.record;
    console.log(_orderByID);
    
    if (_orderByID) {
      setOrderData(_orderByID[0]);
      setError(initailStateOfErrors);
    }
  }, [orderByID]);
  //if order not found show toastr error
  useEffect(() => {
    if (orderByID?.record?.length == 0 || orderByID?.record == []) {
      setError({ ...error, orderID: "Order not found." });
    }
    setApprovedBy("");
    setReason("");
  }, [orderByID]);

  useEffect(() => {
    if (orderID) {
      setOrderData({});
      setError({ ...error, failed: false })
    }
  }, [orderID]);

  const handleSearch = async () => {
    setError(initailStateOfErrors);
    if (orderID) {
      setIsLoading(true);
      dispatch(getOrderByID({ orderID }));
      setIsLoading(false);
    } else {
      setError({ ...error, orderID: "Please enter a valid order ID" });
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/[^\d]/g, ''); // Remove non-digit characters
    if (value === '0') {
      value = ''; // or set it to some default value if needed
    }

    setOrderID(value);
  };

  const handleReasonChange = (e) => {
    setError({ ...error, reason: "" });
    setReason(e.target.value);
  };

  const handleApprovedBy = (e) => {
    setError({ ...error, approvedBy: "" });
    setApprovedBy(e.target.value);
  };

  const handleCancel = async () => {
    setError(initailStateOfErrors);
    if (reason === "") {
      setError({ ...error, reason: "Please enter a reason for cancellation." });
      setRefundModalToggel(false)
      return;
    }

    if (approvedBy === "") {
      setError({ ...error, approvedBy: "Please enter the name of the person who approved the cancellation." });
      setRefundModalToggel(false)
      return;
    }

    setIsLoading(true);
    try {
      const data =  {
        _id: orderData?._id,
        reason,
        approvedBy,
      }

      if(isRefund) {
        data.refundMethod = refundMethod
        data.refundAmount = refundAmount
        data.refund = true
      }
      const response = await postWeb("orders/cancelOrder",data);

      if (response.statusCode === "200") {
        toastr.success("Order cancelled successfully.");
        setOrderData({});
        setOrderID("");
        setReason("");
      } else {
        setError({ ...error, failed: response.msg || "Failed to cancel order." });
      }
    } catch (error) {
      setError({ ...error, failed: response.msg || "An unexpected error occurred. Please try again later." });
    } finally {
      setRefundModalToggel(false)
      setIsLoading(false);
    }
  };
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 2147483649,
    }),
  };
  

  const exportCsv = async () => {
    if (!startDate || !endDate || !cancelledBy?.value || !paymentStatus?.value) {
      toastr.error("Please select start date and end date.");
      return;
    }
     // Check if endDate is earlier than startDate
     if (new Date(endDate) < new Date(startDate)) {
      toastr.error("End date cannot be earlier than start date.");
      return;
  }
    if(refundAmount < 0){
      toastr.error("Refund amount cannot be 0 or negative.");
      return;
    }
    if(refundAmount > orderData?.grandTotal){
      toastr.error("Refund amount cannot be greater than grand total.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await postWeb("orders/listAndExportCancelledOrders", {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        cancelledBy:cancelledBy?.value,
        paymentStatus: paymentStatus?.value,
        isExport:true 
      });    
      if (response.statusCode == "200") {
        if(response?.filePath){
          window.open(`${API_BASE_URL}${response?.filePath}`, '_blank');
          toastr.success("Cancelled orders exported successfully.");
          setStartDate(new Date().toISOString().split('T')[0]);
          setEndDate(new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
          setCancelledBy(cancelledByOptions[0]);
          setPaymentStatus(paymentStatusOptions[0]);
        } else{
          toastr.error(response.msg || "Failed to export cancelled orders.");
        }
      } else {
        toastr.error(response.msg || "Failed to export cancelled orders." );
      }     
    } catch (error) {
      toastr.error( response.msg || "An unexpected error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    if(!refundModalToggel && isExport){
     setOrderData({});
     setOrderID("");
     setReason("");
     setApprovedBy("");
     setError(initailStateOfErrors);
     setStartDate(new Date().toISOString().split('T')[0]);
     setEndDate(new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
     setCancelledBy(cancelledByOptions[0]);
     setPaymentStatus(paymentStatusOptions[0]);
    } 
    if(!isExport && !refundModalToggel){
      setStartDate(new Date().toISOString().split('T')[0]);
      setEndDate(new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
      setCancelledBy(cancelledByOptions[0]);
      setPaymentStatus(paymentStatusOptions[0]);
      setRefund(false);
      setRefundMethod('');
      setRefundAmount('');
      setOrderData({});
    }
  },[isExport, refundModalToggel])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Management | Cancel Order</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Order Management" breadcrumbItem="Cancel Order" />
          <Row className="d-flex align-items-center" style={{ minHeight: '50px' }}>
            <Col sm="12">
              <div className="d-flex align-items-center">
                <label className="d-flex align-items-center">
                  <input
                    type="radio"
                    checked={!isExport}
                    onChange={() => setIsExport(false)}
                  />
                  <span className="ms-2">Cancel Order</span>
                </label>
                <label className="ms-4 d-flex align-items-center">
                  <input
                    type="radio"
                    checked={isExport}
                    onChange={() => setIsExport(true)}
                  />
                  <span className="ms-2">Export Cancelled Order Data</span>
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            {isExport ?
              <Col sm="12" className="mt-3">
                <Row className="d-flex  align-items-center mb-3">
                  <Col sm='3'>
                    <Label className="mr-1" for="cancelBy">Order Cancelled By</Label>
                    <Select
                      styles={customStyles}
                      id="cancelBy"
                      placeholder="Cancelled By"
                      options={cancelledByOptions}
                      value={cancelledBy}
                      onChange={option => setCancelledBy(option)}
                      className="mr-1 mb-2 w-full"
                    />
                    
                  </Col>
                  <Col sm='3'>
                    <Label className="mr-1" for="paymentStatus">Payment Status</Label>
                    <Select
                      styles={customStyles}
                      id="paymentStatus"
                      placeholder="Payment Status"
                      options={paymentStatusOptions}
                      value={paymentStatus}
                      onChange={option => setPaymentStatus(option)}
                      className="mr-1 mb-2 w-full"
                    />
                    
                  </Col>
                 
                </Row>
                <Row>
                  <h5>Date Range</h5>
                  <Col sm="2">
                    <Label for="startDate" style={{ fontSize: '10px' }}>Start Date</Label>
                    <Input
                      disabled={isLoading}
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={formatDate(startDate)}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        if (endDate && new Date(selectedDate) > new Date(endDate)) {
                          setError({ failed: 'Start Date cannot be later than End Date.' });
                        } else {
                          setError(initailStateOfErrors); // Clear errors if valid
                          setStartDate(selectedDate);
                        }
                      }}
                    />
                  </Col>
                  <Col sm="2">
                    <Label for="endDate" style={{ fontSize: '10px' }}>End Date</Label>
                    <Input
                      disabled={isLoading}
                      type="date"
                      name="registrationExpiration"
                      id="registrationExpiration"
                      value={formatDate(endDate)}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        if (startDate && new Date(selectedDate) < new Date(startDate)) {
                          setError({ failed: 'End Date cannot be earlier than Start Date.' });
                        } else {
                          setError(initailStateOfErrors); // Clear errors if valid
                          setEndDate(selectedDate);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Button
                      color="primary"
                      className="mt-4"
                      onClick={exportCsv}
                      disabled={isLoading || !startDate || !endDate || !cancelledBy || !paymentStatus}
                    >
                     Export
                    </Button>
              </Col>
              : <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col sm="12">
                        <div className="me-2 mb-2 d-inline-block">
                          <CardText className="mb-2 pt-2 fs-5 fw-bold">Order ID: </CardText>
                          <div className="input-group">
                            <Input
                              type="text"
                              placeholder="Search Order"
                              className="form-control"
                              value={orderID}
                              onChange={handleChange}
                              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                              invalid={error?.orderID ? true : false}
                            />
                            <Button
                              className="ms-2"
                              color="primary"
                              onClick={handleSearch}
                              disabled={isLoading}
                            >
                              Search
                            </Button>
                          </div>
                          {error?.orderID && <div className="text-danger">{error?.orderID}</div>}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        {orderData && orderID && orderData?._id && (
                          <Row className="mt-4">
                            <Col sm="4">
                              <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                                <strong>Order ID:</strong> {orderData.orderID}
                              </CardText>
                            </Col>
                            <Col sm="6">
                              <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                                <strong>Status:</strong> {orderData.status}
                              </CardText>
                            </Col>
                            <Col sm="4">
                              <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                                <strong>Paid:</strong> {orderData.paid ? "Yes" : "No"}
                              </CardText>
                            </Col>
                            <Col sm="6">
                              <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                                <strong>Category:</strong> {orderData.category}
                              </CardText>
                            </Col>
                            <Col sm="4">
                              <CardText className="mb-2 pt-2 fs-5" style={{ fontSize: '1.25rem' }}>
                                <strong>Grand Total:</strong> SAR {orderData.grandTotal}
                              </CardText>
                            </Col>
                            <Col sm="6">
                              <CardText className="mb-2 pt-2 fs-5 d-flex gap-2 align-items-center " style={{ fontSize: '1.25rem' }}>
                                <strong>Approved By: </strong>
                                <Input
                                  type="text"
                                  placeholder="Approved By"
                                  className="form-control w-50"
                                  value={approvedBy}
                                  onChange={handleApprovedBy}
                                  disabled={isLoading}
                                  invalid={error.approvedBy ? true : false}
                                />
                              </CardText>
                              {error.approvedBy && <div className="text-danger">{error.approvedBy}</div>}
                            </Col>
                            <Row className="mt-3">
                              <Col sm="4">
                                <CardText className="mb-2 pt-2 fs-5 fw-bold">Reason for Cancellation: </CardText>
                                <Input
                                  type="textarea"
                                  style={{ resize: "none" }}
                                  placeholder="Enter reason for cancellation"
                                  className="form-control"
                                  value={reason}
                                  onChange={handleReasonChange}
                                  invalid={error.reason ? true : false}
                                />
                                {error.reason && <div className="text-danger">{error.reason}</div>}
                              </Col>
                            </Row>
                          </Row>
                        )}
                        <Col sm="6">
                          {orderData && orderData?._id && orderID != "" && <Button
                            className="mt-4"
                            color="danger"
                            onClick={()=> orderData?.paid ? setRefundModalToggel(true) :handleCancel()}
                            disabled={isLoading}
                          >
                            Cancel Order
                          </Button>}
                        </Col>
                        {error?.failed && <div className="text-danger fs-4 mt-2">{error.failed}</div>}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>}
          </Row>
        </div>
      </div>
      <Modal
        size="md"
        isOpen={refundModalToggel}
        toggle={() => setRefundModalToggel(!refundModalToggel)}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            <span >
              Refund
            </span>
          </h5>
          <button
            type="button"
            onClick={() => setRefundModalToggel(!refundModalToggel)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Row className="d-flex align-items-center" style={{ minHeight: '50px' }}>
            <Col sm="12">
              <div className="d-flex align-items-center">
                <label className="d-flex align-items-center">
                  <input
                    name="refund"
                    type="radio"
                    checked={!isRefund}
                    onChange={() => setRefund(false)}
                  />
                  <span className="ms-2">Proceed without Refund</span>
                </label>
                <label className="ms-4 d-flex align-items-center">
                  <input
                    name="refund"
                    type="radio"
                    checked={isRefund}
                    onChange={() => setRefund(true)}
                  />
                  <span className="ms-2">Proceed with Refund</span>

                </label>
              </div>
            </Col>
          </Row>
          {isRefund && <Row className="mt-2">
            <Col md="6" className="order-ref121">
              <Label>Amount <span className="mandatory_feild">*</span></Label>
              <Input
                name="refundAmount"
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                disabled={isLoading}
                value={refundAmount}
                onChange={(val) => {
                  // Allow only numeric values
                  const numericValue = val.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

                  // Convert to number (integer or decimal)
                  const convertedValue = numericValue ? parseFloat(numericValue) : 0;

                  setRefundAmount(convertedValue);
                  setError(initailStateOfErrors);
                }}
              />

              {
                error?.refundAmt ? (
                  <Label style={{ color: "red" }}>{error?.refundAmt}</Label>
                ) : null
              }
            </Col>
            <Col md="6" className="order-ref121">
              <Label>Method <span className="mandatory_feild">*</span></Label>
              <select
                name="refundMethod"
                className="form-control"
                placeholder="Enter Method"
                value={refundMethod}
                onChange={(e) => {
                  setRefundMethod(e.target.value)
                  setError(initailStateOfErrors)
                }}
              >
                <option value="">Select Method</option>
                <option value="wallet">Wallet</option>
                <option value="bank">Bank</option>
              </select>
              {
                error.refundMeth ? (
                  <Label style={{ color: "red" }}>{error.refundMeth}</Label>
                ) : null
              }
            </Col>
          </Row>}
          <Row className="modal_btn">
            <Col md="6" className="mt-2 d-flex">
              <Button
                onClick={() => setRefundModalToggel(!refundModalToggel)}
                type="submit"
                // color="primary"
                className="w-md mx-2"
              >Cancel</Button>
              {
                isLoading ? (
                  <Button
                    color="primary"
                    className="btn-rounded d-flex"
                    type="button"
                    disabled
                  >
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                    Processing...
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleCancel()}
                    type="submit"
                    color="primary"
                    className="w-md"
                    disabled={isLoading}
                  >Proceed</Button>
                )
              }
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CancelOrder;
